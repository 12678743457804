export function getPageLayout(story, store) {
  // get the type of Page Header from the page Layout
  let menuHeader = 'default-header';
  if (
    story.data.story.content.hasOwnProperty('header') &&
    typeof story.data?.story.content.header === 'string'
  ) {
    menuHeader = story.data.story.content.header;
  }
  store.dispatch('menu/updateMenuHeader', menuHeader);

  // get the  Page Header text from the page Layout
  let headerText = '';
  if (
    story.data.story.content.hasOwnProperty('header_text') &&
    typeof story.data?.story.content.header_text === 'string'
  ) {
    headerText = story.data.story.content.header_text;
  }
  store.dispatch('menu/updateHeaderText', headerText);

  // get the type of Page Footer from the page Layout
  let footerClass = 'default-footer';
  if (
    story.data.story.content.hasOwnProperty('footer') &&
    typeof story.data?.story.content.footer === 'string'
  ) {
    footerClass = story.data.story.content.footer;
  }
  store.dispatch('menu/updateFooterClass', footerClass);
}
