
export default {
  name: 'page-scaffolder',
  props: {
    story: {
      type: Object,
      required: true,
    },
    region: {
      type: String,
      default: '',
    },
    country: {
      type: String,
      required: false,
      default: '',
    },
  },
};
